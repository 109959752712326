import React from 'react'
import Layout from '../../../components/Layout'
import { CSSTransition } from 'react-transition-group'

const SuccessPage = () => {
  return (
    <Layout>
      <div className='container'>
        <div className='columns'>
          <div className='column is-3 is-offset-1'>
            <h1 className='is-size-3 has-text-weight-semibold'>
              Say Hi
            </h1>
          </div>
          <div className='column is-7 content'>
            <CSSTransition
              key={'about-page'}
              timeout={5000}
              classNames='element-appear'
              appear
              in
            >
              <div>
                <p>Thanks for submitting your message. </p>
                <p> I will get back to you soon! If I don't respond after a week, feel free to follow up.</p>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SuccessPage
